.ReactModal__Overlay {
    overflow-y: auto !important;
}

.modal-small {
    position: relative !important;
    width: 75%;
    inset: 10%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}