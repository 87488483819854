.uk-card-success {
  background: #32d296 !important;
  color: #fff !important;
}

.uk-card-success a,
.uk-card-success span,
.uk-card-success h1,
.uk-card-success h2,
.uk-card-success h3,
.uk-card-success h4 {
  color: #fff !important;
}

.uk-card-warning {
  background-color: #fca266 !important;
  color: #fff !important;
}

.uk-card-warning span,
.uk-card-warning h1,
.uk-card-warning a,
.uk-card-warning h3,
.uk-card-warning h4,
.uk-card-warning h5  {
  color: #fff !important;
}

.uk-card-alternative {
  background-color: #5F7ACA !important;
}

.uk-card-alternative span,
.uk-card-alternative h1,
.uk-card-alternative a,
.uk-card-alternative h3,
.uk-card-alternative h4,
.uk-card-alternative h5 {
  color: #fff !important;
}
