.month{
  text-transform: capitalize;
  text-align: center;
  border: 1px solid #eeeeee;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 700;
  -webkit-box-shadow: 2px 1px 5px 0px rgba(66,66,66,1);
  -moz-box-shadow: 2px 1px 5px 0px rgba(66,66,66,1);
  box-shadow: 2px 1px 5px 0px rgba(66,66,66,1);
  min-height: 100%;
}

.week-day {
  width: 5%;
  background-color: #1e3246;
  color: #eeeeee;
  font-weight: 300;
}

.day {
  width: 5%;
  text-align: center;
  font-weight: 200;
}

.day.empty {
  background-color: #b8c1c7;
  cursor: default !important;
}

.day:hover{
  opacity: 0.6;
  cursor: pointer;
}

.has-event {
  color: #24a4d4;
  font-weight: 900;
}

.pastDay {
  text-decoration: line-through;
}