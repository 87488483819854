/* ===== Scrollbar CSS ===== */
  /* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #839188 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #839188;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.ReactModal__Overlay {
  z-index: 999 !important;
}