.uk-button-success {
    background-color: #32d296 !important;
    color: #fff !important;
}

.uk-button-warning {
    background-color: #fca266 !important;
    color: #fff !important;
}

.uk-button-danger {
    background-color: #e14461 !important;
    color: #fff !important;
}

.uk-button-success:disabled,
.uk-button-error:disabled,
.uk-button-warning:disabled {
    opacity: 0.5 !important;
}

