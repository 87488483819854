input {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 300px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
  position: absolute;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  text-transform: uppercase;
  z-index: 1000;
  position: absolute;
  top: 8vh;
}

.suggestions li {
  padding: 0.5rem;
  background-color: #fff;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #6e6e6e !important;
  color: #f7eead;
  cursor: pointer;
}
.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
